<template>
  <div class="container">
     <el-alert
    title="当需要一个新的合同时，可通过合同模板来生成；点击生成文件按钮后，完成填写信息后最终会得到一份新的合同文件，可使用生成的文件发起合同签署"
    type="warning"
    :closable="false">
  </el-alert>
    <el-form :inline="true" class="m-t10">
      <el-form-item>
        <el-button type="primary" @click="openCreate">创建合同模板</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #createTime="{ row }">
        <div>
          {{ new Date(row.createTime).Format('yyyy-MM-dd hh:mm:ss') }}
        </div>
      </template>
      <template #handler="{ row }">
        <div>
         <el-button type="primary" size="small" @click="editTemplate(row)">编辑</el-button>
         <el-button type="success" size="small" @click="createDocFile(row)">生成文件</el-button>
        </div>
      </template>
    </auto-table>

    <el-dialog
      :close-on-click-modal="false"
      title="新增模板"
      :visible.sync="show"
      width="30%"
    >
      <div>
        <eFileUpload ref="fu" @success="fileUploadSuccess"></eFileUpload>
        <el-form>
          <el-form-item label="模板名称" required>
            <el-input v-model="form.name" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="getUploadUrl">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import eFileUpload from "../../../components/upload/eFileUpload.vue";

var timer = "";
export default {
  components: {
    eFileUpload,
  },
  data() {
    return {
      form: {
        name: "",
        type: 0,
      },
      show: false,
      DataList: [],
      Option: [
        { name: "模板名称", value: "docTemplateName" },
        { name: "创建时间", value: "createTime", type: "custom" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",

      fileData: "",
      fileUrl: "",
      upload: {
        fileId: "",
        path: "",
      },

      loading:""
    };
  },
  mounted() {
    
    this.getList(1);
  },
  destroyed() {
    clearInterval(timer);
  },
  methods: {
    createDocFile(row){
        let data = {
        docTemplateId: row.docTemplateId,
        // customBizNum:'2131',
        redirectUrl: this.$href+"#/main/fileCreateSuccess",
      };
      this.$post("/user/eTemp/getCreateUrl", data).then(res=>{
        window.location.href = res.data.data.docTemplateFillUrl
      })
    },

    editTemplate(row){
        let data = {
        docTemplateId: row.docTemplateId,
        redirectUrl: this.$href+"#/main/eTemplateList",
      };
      this.$post("/user/eTemp/getTemplateEditUrl", data).then(res=>{
        window.location.href = res.data.data.docTemplateEditUrl
      })
    },

    getUploadUrl() {
      if (!this.form.name) {
        this.$message("请填写模板名称");
        return;
      }
      let data = {
        fileName: this.fileData.name,
        fileSize: this.fileData.size,
        filePath: this.fileUrl,
      };
       this.loading = this.$loading({
          lock: true,
          text: '模板文件上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      this.$get("user/esign/getUploadUrl", data).then((res) => {
        let v = res.data.data;
        this.upload.fileId = v.fileId;
        this.upload.path = v.fileUploadUrl;
        timer = setInterval(() => {
          this.$get("user/esign/getFileStatus", {
            id: this.upload.fileId,
          }).then((res) => {
            if (
              res.data.data.fileStatus == 2 ||
              res.data.data.fileStatus == 5
            ) {
              clearInterval(timer);
              this.createTemplate();
              this.loading.close()
            }
          });
        }, 1000);
      });
    },
    createTemplate() {
      let data = {
        docTemplateName: this.form.name,
        docTemplateType: this.form.type,
        fileId: this.upload.fileId,
        redirectUrl: this.$href+"#/main/eTemplateList",
      };
      this.$post("/user/eTemp/getTemplateCreateUrl", data).then(res=>{
        window.location.href = res.data.data.docTemplateCreateUrl
      })
    },
    fileUploadSuccess(v) {
      this.fileData = v.fileData;
      this.fileUrl = v.fileUrl;
    },
    openCreate() {
      this.show = true;
    },
    //列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/eTemp/queryTemplateList",
        params: {
          currentPage: page,
          pageSize: 10,
        },
      }).then((res) => {
        this.DataList = res.data.data.docTemplates;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>